@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Cinzel', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
.icon-size {
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
}
.icon-size-main {
  width: 40px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  filter: grayscale(100%) brightness(0) invert(0);

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
