/* General App Styling */
.App {
  text-align: center;
}

/* Logo Styling */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Header Styling */
.App-header {
  background-color: #DDDED8;
  min-height: 100vh;
  width: 100%; /* Ensure it spans the entire viewport width */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  margin: 0; /* Remove default margin that could cause overflow */
  padding: 0; /* Remove any padding that could extend the width */
  box-sizing: border-box; /* Ensure padding/border are part of width/height */
}

/* Link Styling */
.contract-link {
  text-decoration: none; /* No underline by default */
}

.contract-link:hover {
  text-decoration: underline; /* Underline on hover */
}
.filter-black {
  filter: grayscale(100%) brightness(0) invert(1);
}

/* Keyframes for Animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
